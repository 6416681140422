import React from "react";
import styles from "./Button.module.scss";

type ButtonProps = {
  text: string, 
  onClickFunction: () => void, 
  type: string, 
  toggle: string, 
  target: string
}

const Button: React.FC<ButtonProps> = ({text, onClickFunction, type, toggle, target}) => {
  return (
    <div 
    data-type={type}
    data-bs-toggle={toggle}
    data-bs-target={target} onClick={() => onClickFunction()} className={styles.button}
    >
      {text}
    </div>
  );
};

export default Button;
