import React from "react";
import { Outlet } from "react-router-dom";

import Sidebar from "../../components/Sidebar";
import styles from "./AdminPanel.module.scss"

const AdminPanel: React.FC = () => {

    return (
      <div className={styles.adminBackground}>
        <Sidebar />
        <div className={styles.adminPanelContent}>
          <Outlet/>
        </div>
      </div>
    );
};

export default AdminPanel;
