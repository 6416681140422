import React from "react";

import { ReactComponent as YouTubeFileIco } from "../../../assets/ico/addYouTube.svg";

import styles from "./YouTubeBlock.module.scss";

type YouTubeBlockProps = {
    index: number,
    value: string,
    updateInputValue: (index: number, value: string) => void
};

const YouTubeBlock: React.FC<YouTubeBlockProps> = ({index, value, updateInputValue}) => {
  return (
    <div className={styles.youtubeBlock}>
      <YouTubeFileIco />
      YouTube:
      <input
        className={styles.youTubeInput}
        value={value}
        onChange={(event) => updateInputValue(index, event.target.value)}
      />
    </div>
  );
};

export default YouTubeBlock;
