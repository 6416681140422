import React from 'react';
import { useNavigate } from "react-router-dom";
import { useSelector} from "react-redux";

import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

import {setUser } from "../../store/adminUser/adminUserSlice";
import {selectSessionModalShow } from "../../store/sessionModal/sessionModalSlice";
import { useAppDispatch } from '../../store/store';
import { toggleModal } from "../../store/sessionModal/sessionModalSlice";


const WarningModal: React.FC = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    
    const {show} = useSelector(selectSessionModalShow);

    const handleClose = () => {
        localStorage.clear();
        dispatch(setUser(null));
        navigate("/login");
        dispatch(toggleModal(false));
      };

  return (
    <Modal
      data-bs-theme="dark"
      show={show}
      onHide={() => dispatch(toggleModal(false))}
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header closeButton>
        <Modal.Title>Предупреждение</Modal.Title>
      </Modal.Header>
      <Modal.Body>Сессия истекла. Авторизируйтесь снова!</Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Авторизироваться
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default WarningModal