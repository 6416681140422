import React from "react";
import debounce from "lodash.debounce";

import styles from "./Search.module.scss";

import { useAppDispatch } from "../../store/store";
import toast from "react-hot-toast";

type InputPtops = {
  inputValue: string;
  setInputValue: React.Dispatch<React.SetStateAction<string>>;
  setSearchValue: (value: string) => void;
  setPaginationNumber: React.Dispatch<React.SetStateAction<number>>; 
}

const Input: React.FC<InputPtops> = ({inputValue, setInputValue, setSearchValue, setPaginationNumber}) => {
  const dispatch = useAppDispatch();

  const MIN_PAGINATION_VALUE = 2;
  const MAX_PAGINATION_VALUE = 15;

  const onChangeInput = (event: React.FormEvent<HTMLInputElement>) => {
    setInputValue((event.target as HTMLInputElement).value);
    updateSearchValue((event.target as HTMLInputElement).value);
  };

  const onClickClear = () => {
    setPaginationNumber(0);
    setInputValue("");
    setSearchValue("");
  };

  const updateSearchValue = React.useCallback(
    debounce((str: string) => {
      if (
        str.length >= MIN_PAGINATION_VALUE &&
        str.length <= MAX_PAGINATION_VALUE
      ) {
        setPaginationNumber(0);
        setSearchValue(str);
      } else {
        toast.error(
          `Длина значения должна быть не менее ${MIN_PAGINATION_VALUE} и не более ${MAX_PAGINATION_VALUE} символов!`
        );
        onClickClear();
      }
    }, 700),
    [dispatch]
  );

  return (
    <div className={styles.searchBlock}>
      <input
        type="text"
        value={inputValue}
        onChange={onChangeInput}
        className="form-control"
        placeholder="Поиск..."
      />
      {inputValue && (
        <div onClick={onClickClear} className={styles.clearInput}>
          x
        </div>
      )}
    </div>
  );
};

export default Input;
