import React, { useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { jwtDecode } from "jwt-decode";

import "./Sidebar.scss";

import SidebarButton from "../SidebarButton";

import { ReactComponent as UsersIco } from "../../assets/ico/users.svg";
import { ReactComponent as DraftsIco } from "../../assets/ico/drafts.svg";
import { ReactComponent as ReviewIco } from "../../assets/ico/review.svg";
import { ReactComponent as TournamentsIco } from "../../assets/ico/tournaments.svg";
import { ReactComponent as SettingsIco } from "../../assets/ico/settings.svg";
import { ReactComponent as ExitIco } from "../../assets/ico/exit.svg";
import { ReactComponent as NightIco } from "../../assets/ico/night.svg";
import { ReactComponent as DayIco } from "../../assets/ico/day.svg";
import { ReactComponent as ShowArrowIco } from "../../assets/ico/showArrow.svg";
import { ReactComponent as MobileMenuIco } from "../../assets/ico/mobileMenu.svg";
import { ReactComponent as ArchiveIco } from "../../assets/ico/archive.svg";

import { setUser } from "../../store/adminUser/adminUserSlice";
import {
  setIsShrinkView,
  setIsDarkMode,
  selectTheme,
} from "../../store/theme/themeSlice";
import { selectAdminUser } from "../../store/adminUser/adminUserSlice";

export type JwtProps = {
  exp: string;
  nickname: string;
  roles: string[];
  sub: string;
};

const Sidebar: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { isDarkMode, isShrinkView } = useSelector(selectTheme);
  const [showExit, setShowExit] = React.useState(false);
  const { user: adminUser } = useSelector(selectAdminUser);
  const token = adminUser !== null ? adminUser.access_token : "";

  const decoded = jwtDecode<JwtProps>(token);
  const adminRoles = decoded.roles;
  const nickname = decoded.nickname;

  const [clickButton, setClickButton] = React.useState(
    window.location.pathname.split("/")[2]
  );

  const sidebarRef = useRef<HTMLDivElement>(null);

  const handleSidebarView = () => {
    dispatch(setIsShrinkView(!isShrinkView));
  };

  const handleThemeChange = () => {
    dispatch(setIsDarkMode());
    document.body.classList.toggle("dark");
  };

  React.useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        sidebarRef.current &&
        !event.composedPath().includes(sidebarRef.current)
      ) {
        dispatch(setIsShrinkView(true));
        setShowExit(false);
      }
    };

    document.body.addEventListener("click", handleClickOutside);

    return () => document.body.removeEventListener("click", handleClickOutside);
  }, [dispatch]);

  const onClickExit = () => {
    localStorage.clear();
    navigate("/login");
    dispatch(setUser(null));
  };

  return (
    <div
      ref={sidebarRef}
      className={`sidebar-container${isShrinkView ? " shrink" : ""}`}
    >
      <button
        className="sidebar-viewButton"
        type="button"
        aria-label={isShrinkView ? "Expand Sidebar" : "Shrink Sidebar"}
        title={isShrinkView ? "Показать" : "Скрыть"}
        onClick={handleSidebarView}
      >
        <ShowArrowIco />
      </button>
      <div onClick={handleSidebarView} className={"sidebar-mobile-menu"}>
        <MobileMenuIco />
      </div>
      <div className="sidebar-wrapper">
        <div className="sidebar-themeContainer">
          <label
            data-labelfor="theme-toggle"
            className={`sidebar-themeLabel${isDarkMode ? " switched" : ""}`}
          >
            <input
              className="sidebar-themeInput"
              type="checkbox"
              id="theme-toggle"
              onChange={handleThemeChange}
            />
            <div className="sidebar-themeType light">
              <DayIco />
            </div>
            <div className="sidebar-themeType dark">
              <NightIco />
            </div>
          </label>
        </div>
        <ul className="sidebar-list">
          <SidebarButton
            svg={<DraftsIco className="fillIco"/>}
            text={"Мои Статьи"}
            link={"drafts"}
            clickButton={clickButton}
            setClickButton={setClickButton}
          />
          {adminRoles.find(
            (obj) => obj === "ADMIN" || obj === "HEAD_EDITOR"
          ) && (
            <SidebarButton
              svg={<ReviewIco className="fillIco"/>}
              text={"Ревью"}
              link={"review"}
              clickButton={clickButton}
              setClickButton={setClickButton}
            />
          )}
          {adminRoles.find((obj) => obj === "ADMIN") && (<SidebarButton
            svg={<ArchiveIco className="noFillIco"/>}
            text={"Архив"}
            link={"archive"}
            clickButton={clickButton}
            setClickButton={setClickButton}
          />)}
          <SidebarButton
            svg={<TournamentsIco className="fillIco"/>}
            text={"Турниры"}
            link={"tournaments"}
            clickButton={clickButton}
            setClickButton={setClickButton}
          />
          {adminRoles.find((obj) => obj === "ADMIN") && (
            <SidebarButton
              svg={<UsersIco className="noFillIco"/>}
              text={"Пользователи"}
              link={"users"}
              clickButton={clickButton}
              setClickButton={setClickButton}
            />
          )}
          <SidebarButton
            svg={<SettingsIco className="noFillIco"/>}
            text={"Настройки"}
            link={"settings"}
            clickButton={clickButton}
            setClickButton={setClickButton}
          />
        </ul>
        {showExit && (
          <div className="exitBlock">
            <div className="sidebar-listItem">
              <div>
                <span className="sidebar-listItemText">Выход</span>
                <ExitIco onClick={() => onClickExit()} className="exitImg" />
              </div>
            </div>
          </div>
        )}
        <div
          onClick={() => setShowExit(!showExit)}
          className="sidebar-profileSection"
        >
          <img
            src="https://assets.codepen.io/3306515/i-know.jpg"
            width="40"
            height="40"
            alt="Monica Geller"
          />
          <span>{nickname}</span>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
