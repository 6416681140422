import React from 'react';
import Card from 'react-bootstrap/Card';
import Placeholder from 'react-bootstrap/Placeholder';

import styles from "./DraftLoading.module.scss"

type DraftLoadingProps ={
    length: number,
}

const DraftLoading: React.FC<DraftLoadingProps> = ({length}) => {
    return length ? (
        <>
          {[...Array(length)].map((_,index) => (
            <Card key={index} className={styles.cardBackground}>
            <svg viewBox="-2.5 0 32 32" version="1.1" xmlns="http://www.w3.org/2000/svg" fill="#000000" stroke="#000000" strokeWidth="0.00032"><g id="SVGRepo_bgCarrier" strokeWidth="0"></g><g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g><g id="SVGRepo_iconCarrier"> <g id="icomoon-ignore"> </g> <path d="M0 3.2v25.6h26.667v-25.6h-26.667zM1.067 19.307l7.467-7.84 7.413 7.413 3.733-3.2 5.547 5.653h-24.16v-2.027zM25.6 27.733h-24.533v-5.333h24.533v5.333zM25.6 20.213l-5.813-6.027-3.733 3.2-7.52-7.467-7.467 7.84v-13.493h24.533v15.947z" fill="grey"> </path> <path d="M19.733 11.733c1.493 0 2.667-1.173 2.667-2.667s-1.173-2.667-2.667-2.667c-1.493 0-2.667 1.173-2.667 2.667s1.173 2.667 2.667 2.667zM19.733 7.467c0.907 0 1.6 0.693 1.6 1.6s-0.693 1.6-1.6 1.6c-0.907 0-1.6-0.693-1.6-1.6s0.693-1.6 1.6-1.6z" fill="grey"> </path> </g></svg>
            <Card.Body>
              <Placeholder as={Card.Title} animation="glow">
                <Placeholder xs={6} />
              </Placeholder>
              <Placeholder as={Card.Text} animation="glow">
                <Placeholder xs={7} /> <Placeholder xs={4} /> <Placeholder xs={4} />{' '}
                <Placeholder xs={6} /> <Placeholder xs={8} />
              </Placeholder>
              <Placeholder.Button variant="secondary" xs={6} />
            </Card.Body>
          </Card>
          ))}
        </>
      ) : <></>;
}

export default DraftLoading