import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store";

export type AdminUserProps = {
  access_token: string,
  token_type: string,
  uid: string
}

interface adminUserSliceState{
  user: null | AdminUserProps
  roles: [] | string[]
}

const initialState: adminUserSliceState = {
  user: null,
  roles: []
};

export const adminUserSlice = createSlice({
  name: "adminUser",
  initialState,
  reducers: {
    setUser: (state, action) => {
      state.user = action.payload;
    },
    setRoles: (state, action) => {
      state.roles = action.payload;
    }
  },
});

export const selectAdminUser = (state: RootState) => state.adminUserSlice;

export const { setUser, setRoles} = adminUserSlice.actions;

export default adminUserSlice.reducer;
