import React from "react";

import styles from "./Settings.module.scss";

const Settings: React.FC = () => {
  return (
    <div className={styles.background}>
      <h1>В разработке✍️</h1>
      <p>Спасибо за ожидание. Эта часть будет скоро реализована!</p>
    </div>
  );
};

export default Settings;
