import React from "react";

import styles from "./AddBlocksButton.module.scss";

import { ReactComponent as PlusIco } from "../../assets/ico/plus.svg";
import AddBlocksModal from "../AddBlocksModal";
import { BlockProps } from "../../store/ownDrafts/ownDraftsSlice";
import toast from "react-hot-toast";
import { checkEmptyBlock } from "../../utils/checkEmptyBlock";

type AddBlocksButtonProps = {
  index: number;
  draftTitle: string;
  setBlocksValues: React.Dispatch<React.SetStateAction<BlockProps[]>>;
  blocksValues: BlockProps[];
  addMedia: (formData: FormData) => Promise<any>;
  saveDraft: (newBlocks?: BlockProps[], bannerUrl?: string) => void
};

const AddBlocksButton: React.FC<AddBlocksButtonProps> = ({
  index,
  draftTitle,
  setBlocksValues,
  blocksValues,
  addMedia,
  saveDraft
}) => {
  const [showBlocks, setShowBlocks] = React.useState(false);

  const tools = React.useRef<HTMLDivElement>(null);
  const toolsButton = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        tools.current &&
        !event.composedPath().includes(tools.current) &&
        toolsButton.current &&
        !event.composedPath().includes(toolsButton.current)
      ) {
        setShowBlocks(false);
      }
    };

    document.body.addEventListener("click", handleClickOutside);

    return () => document.body.removeEventListener("click", handleClickOutside);
  }, []);

  const createNewBlock = (index: number, type: string) => {
    //проверить валидацию текущего index 
    //либо переделать отпарвку блоков на бек через redux

    const resp = checkEmptyBlock(blocksValues);

    if(!resp){
      toast.error("Заполните все пустые поля!");
      return;
    }

    if (draftTitle.length === 0) {
      toast.error("Добавьте заголовок статьи!");
      return;
    }

    let newBlock: BlockProps = { type: "", data: "" };

    if (type === "TEXT") {
      newBlock = { type: "TEXT", data: "" };
    } else if (type === "QUOTE") {
      newBlock = {
        type: "QUOTE",
        data: {
          text: "",
          author_name: "",
          link: "",
        },
      };
    } else if (type === "YOUTUBE") {
      newBlock = {
        type: "VIDEO",
        data: {
          provider: "YOUTUBE",
          type: "",
          url: "",
        },
      };
    }

    if (newBlock) {
      setBlocksValues((prev) => [
        ...prev.slice(0, index + 1),
        newBlock,
        ...prev.slice(index + 1),
      ]);
    }
  };

  return (
    <div>
      <PlusIco
        ref={toolsButton}
        className={styles.plusIco}
        onClick={() => setShowBlocks(!showBlocks)}
      />
      {showBlocks && (
        <AddBlocksModal
          index={index}
          setShowBlocks={setShowBlocks}
          tools={tools}
          createNewBlock={createNewBlock}
          blocksValues={blocksValues}
          setBlocksValues={setBlocksValues}
          addMedia={addMedia}
          saveDraft={saveDraft}
        />
      )}
    </div>
  );
};

export default AddBlocksButton;
