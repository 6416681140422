import React from 'react';

import styles from "./NotFound.module.scss"

import FindErrorIco from "../../assets/ico/favorite-smile.png";
import PageErrorIco from "../../assets/ico/error-smile.png";
import NonAccessIco from "../../assets/ico/profile-smile.png";

type NotFoundProps = {
    error: string,
    text: string,
    ico: string
}

type IcoTypeOptions = {
    [key: string]: string;
  };

const NotFound:React.FC<NotFoundProps> = ({error, text, ico}) => {

    const icoType:  IcoTypeOptions = {
        findError: FindErrorIco,
        pageError: PageErrorIco,
        accessError: NonAccessIco
      };

  return (
    <div className={styles.notFound}>
        <h1>{error}{ico && <img src={icoType[ico]} alt='Картинка'/>}</h1>
        <p>{text}</p>
    </div>
  )
}

export default NotFound;