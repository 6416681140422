import React from "react";
import Carousel, { ControlProps } from "nuka-carousel";

import { ReactComponent as DeletePhotoIco } from "../../../assets/ico/trash.svg";

import styles from "./CarouselBlock.module.scss";

import { PictureBlockProps } from "../../../store/ownDrafts/ownDraftsSlice";
import { ClipLoader } from "react-spinners";

type CarouselBlockProps = {
  index: number;
  isLoading: boolean;
  photos: PictureBlockProps[];
  addCarouselPhoto: (
    event: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => void;
  deleteCarouselPhoto: (url: string, index: number, photoIndex: number) => void;
  updateInputValue: (index: number, value: string, photoIndex: number) => void
};

const CarouselBlock: React.FC<CarouselBlockProps> = ({
  index,
  photos,
  addCarouselPhoto,
  deleteCarouselPhoto,
  updateInputValue,
  isLoading,
}) => {
  const [slideIndex, setSlideIndex] = React.useState(0);

  const deletePhoto = (url: string, index: number, photoIndex: number) => {
    deleteCarouselPhoto(url, index, photoIndex);

    if (photoIndex > 0) {
      setSlideIndex(photoIndex - 1);
    }
  };

  return (
    <div className={styles.carouselBlock}>
      <Carousel slideIndex={slideIndex}>
        {isLoading ? (
          <div className={styles.loadingBlock}><ClipLoader color="white" /></div>
        ) : (
          photos.map((img, photoIndex) => (
            <div key={photoIndex} className={styles.swiperBlock}>
              <img src={img.url} alt="Фото" />

              <div className={styles.pictureAlt}>
                <input type="text" value={img.alt !== null ? img.alt : ""} onChange={(event) => updateInputValue(index, event.target.value, photoIndex)} placeholder="Описание фото..." />
              </div>

              <DeletePhotoIco
                onClick={() => deletePhoto(img.url, index, photoIndex)}
                className={styles.deleteCarouselPhoto}
              />

              <label
                htmlFor={"addCarousel" + index}
                className={styles.addCarouselPhotoButton}
              >
                +
              </label>
              <input
                onChange={(event) => addCarouselPhoto(event, index)}
                className={styles.carouselInput}
                id={"addCarousel" + index}
                type="file"
              />
            </div>
          ))
        )}
      </Carousel>
    </div>
  );
};

export default CarouselBlock;
