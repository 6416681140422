import React from "react";
import Select, { SelectChangeEvent } from "@mui/material/Select";

import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";

import styles from "./FilterParameters.module.scss";

import { useAppDispatch } from "../../store/store";

type FilterParametersProps = {
  orderValue: string;
  statusValue?: string;
  languageValue: string;
  setSortValue: React.Dispatch<React.SetStateAction<string>>;
  setStatusValue?: React.Dispatch<React.SetStateAction<string>>;
  setArticleLanguage: React.Dispatch<React.SetStateAction<string>>;
  setPaginationNumber: React.Dispatch<React.SetStateAction<number>>;
};

const FilterParameters: React.FC<FilterParametersProps> = ({
  orderValue,
  statusValue,
  languageValue,
  setSortValue,
  setStatusValue,
  setPaginationNumber,
  setArticleLanguage,
}) => {
  const dispatch = useAppDispatch();
  const path = window.location.pathname.split("/")[2];

  const handleChangeOrder = (event: SelectChangeEvent) => {
    setPaginationNumber(0);
    setSortValue(event.target.value);
  };

  const handleChangeStatus = (event: SelectChangeEvent) => {
    setPaginationNumber(0);
    setStatusValue && setStatusValue(event.target.value);
  };

  const handleChangeLanguage = (event: SelectChangeEvent) => {
    setPaginationNumber(0);
    setArticleLanguage(event.target.value);
  };

  return (
    <div className={styles.filterParameters}>
      <div className={styles.filterParameter}>
        <FormControl sx={{ minWidth: 150 }} size="small">
          <InputLabel id="order-label">Сортировка</InputLabel>
          <Select
            labelId="order"
            id="demo-simple-select-autowidth"
            value={orderValue}
            onChange={handleChangeOrder}
            autoWidth
            label="Сортировка"
          >
            <MenuItem className={styles.menuSelect} value={"NEW"}>
              Новые
            </MenuItem>
            <MenuItem value={"OLD"}>Старые</MenuItem>
          </Select>
        </FormControl>
      </div>
      {statusValue && (
        <div className={styles.filterParameter}>
          <FormControl sx={{ minWidth: 150 }} size="small">
            <InputLabel id="status-label">Статус</InputLabel>
            {path === "drafts" && (
              <Select
                labelId="status-label"
                id="status"
                value={statusValue}
                onChange={handleChangeStatus}
                autoWidth
                label="Статус"
              >
                <MenuItem value={"DRAFT"}>Черновики</MenuItem>
                <MenuItem value={"REVIEW"}>На проверке</MenuItem>
                <MenuItem value={"ARCHIVED"}>В архиве</MenuItem>
                <MenuItem value={"PUBLISHED"}>Опубликованные</MenuItem>
              </Select>
            )}
          </FormControl>
        </div>
      )}
      <div className={styles.filterParameter}>
        <FormControl sx={{ minWidth: 150 }} size="small">
          <InputLabel id="language-label">Язык</InputLabel>
          <Select
            labelId="language-label"
            id="demo-simple-select-autowidth"
            value={languageValue}
            onChange={handleChangeLanguage}
            autoWidth
            label="Язык"
          >
            <MenuItem value="">
              <em>Выбрать</em>
            </MenuItem>
            <MenuItem value={"EN"}>English</MenuItem>
            <MenuItem value={"RU"}>Русский</MenuItem>
            <MenuItem value={"UA"}>Українська</MenuItem>
            <MenuItem value={"FR"}>Français</MenuItem>
            <MenuItem value={"DE"}>Deutsch</MenuItem>
          </Select>
        </FormControl>
      </div>
    </div>
  );
};

export default FilterParameters;
