import qs from "qs";
import React from "react";
import axios from "axios";
import { toast } from "react-hot-toast";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import styles from "./Users.module.scss";

import { ReactComponent as MenuIco } from "../../assets/ico/menu.svg";

import Input from "../../components/Input";
import RoleTag from "../../components/RoleTag";
import Pagination from "../../components/Pagination";
import ModalWindow from "../../components/ModalWindow";
import WarningModal from "../../components/WarningModal";
import TableRowsLoader from "../../components/TableRowsLoader";

import {
  setCurrentUser,
  clearCurrentUser,
  setAddRole,
  fetchUsers,
  changeUserStatus,
  selectUsers,
  UserProps,
} from "../../store/users/usersSlice";
import { selectAdminUser } from "../../store/adminUser/adminUserSlice";
import { toggleModal } from "../../store/sessionModal/sessionModalSlice";
import { selectRoles } from "../../store/roles/rolesSlice";
import { useAppDispatch } from "../../store/store";

const Users: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { currentUser, pagesAmounts, allUsersSize, status, users } =
    useSelector(selectUsers);
  const { user: adminUser } = useSelector(selectAdminUser);
  const { roles } = useSelector(selectRoles);
  const token = adminUser !== null ? adminUser.access_token : "";

  const isSearch = React.useRef(false);
  const isMounted = React.useRef(false);

  const [paginationNumber, setPaginationNumber] = React.useState(0);
  const [searchValue, setSearchValue] = React.useState('');

  const [inputValue, setInputValue] = React.useState("");

  const openModal = (obj: UserProps) => {
    dispatch(clearCurrentUser());
    dispatch(setCurrentUser(obj));
  };

  const addRole = async (obj: string) => {
    try {
      if (currentUser === null) {
        return;
      }
      
      const currentRoles = [];
      const current = users.find((obj) => obj.id === currentUser.id);
      if (!current) {
        return;
      }
      const role = current.roles;
      if (role !== null) {
        role.map((obj) => currentRoles.push(obj));
      }
      currentRoles.push(obj);


      console.log(currentRoles)
      await axios.patch(
        `/api/v1/admin/user/${currentUser.id}/roles`,
        currentRoles,
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      );

      dispatch(setAddRole(obj));
    } catch (err) {
      console.log(err);
      toast.error("Произошла ошибка! Попробуйте позже.");
    }
  };

  React.useEffect(() => {
    if (status === "success") {
      console.log(pagesAmounts, paginationNumber + 1)
      if (pagesAmounts < paginationNumber + 1) {
        setPaginationNumber(0);
      }
    }
  }, [status, pagesAmounts, paginationNumber]);

  React.useEffect(() => {
    if (isMounted.current) {
      const queryString = qs.stringify({
        paginationNumber,
        searchValue,
      });

      navigate(`?${queryString}`);
    }

    isMounted.current = true;
  }, [paginationNumber, searchValue, navigate]);

  React.useEffect(() => {
    if (window.location.search) {
      const params = qs.parse(window.location.search.substring(1));

      setPaginationNumber(Number(params.paginationNumber));
      setSearchValue(String(params.searchValue));

      setInputValue(
        typeof params.searchValue === "string" ? params.searchValue : ""
      );

      isSearch.current = true;
    }
  }, []);

  React.useEffect(() => {
    window.scrollTo(0, 0);

    if (!isSearch.current) {

      if(!window.location.search){
        setPaginationNumber(0);
        setSearchValue('');
      }

      dispatch(fetchUsers({ token, paginationNumber, searchValue }));
    }

    isSearch.current = false;
  }, [
    dispatch,
    token,
    searchValue,
    paginationNumber,
  ]);

  React.useEffect(() => {
    if (status === "error") {
      dispatch(toggleModal(true));
    }
  }, [status, dispatch]);

  const updateUserStatus = async () => {
    if (currentUser === null) {
      return;
    }
    if (currentUser.disabled === false) {
      await axios.patch(
        `/api/v1/admin/user/${currentUser.id}/block`,
        {},
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      );
    } else {
      await axios.patch(
        `/api/v1/admin/user/${currentUser.id}/unblock`,
        {},
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      );
    }
    dispatch(changeUserStatus());
  };

  return status === "error" ? (
    <WarningModal />
  ) : (
    <div className={styles.usersBlock}>
      <div className={styles.usersBlockHeader}>
        <h1> Пользователи </h1>
        <Input inputValue={inputValue}
            setInputValue={setInputValue}
            setSearchValue={setSearchValue}
            setPaginationNumber={setPaginationNumber}/>
      </div>
      <div className={styles.userTable}>
        <ModalWindow
          button={updateUserStatus}
          id="banModal"
          content={
            <h3 className="modal-title fs-5" id="staticBackdropLabel">
              {currentUser !== null && currentUser.disabled
                ? "Разбанить"
                : "Забанить"}{" "}
              юзера {currentUser !== null && currentUser.nickname} ?
            </h3>
          }
        />
        <ModalWindow
          id="addRoleModal"
          header={"Добавление ролей"}
          content={
            currentUser !== null &&
            (currentUser.roles !== null
              ? roles
                  .filter(
                    (allRoles) =>
                      !currentUser.roles?.find((obj) => obj === allRoles)
                  )
                  .map((obj: string) => (
                    <div
                      className={styles.role}
                      onClick={() => addRole(obj)}
                      key={obj}
                    >
                      {obj}
                    </div>
                  ))
              : roles.map((obj) => (
                  <div
                    className={styles.role}
                    onClick={() => addRole(obj)}
                    key={obj}
                  >
                    {obj}
                  </div>
                )))
          }
        />
        <table className="table table-striped">
          <thead>
            <tr className={styles.tableHead}>
              <th className={styles.firstColumn} scope="col">
                Номер
              </th>
              <th scope="col">Никнейм</th>
              <th scope="col">Роль</th>
              <th scope="col">Статус</th>
            </tr>
          </thead>
          <tbody>
            {status === "loading" ? (
              <TableRowsLoader rowsNum={15} />
            ) : status === "success" && allUsersSize ? (
              users.map((obj) => (
                <tr key={obj.id}>
                  <td className={styles.firstColumn}>{obj.phone}</td>
                  <td>{obj.nickname}</td>
                  <td>
                    <div className={styles.rolesBlock}>
                      <div className={styles.backgroundTags}>
                        {obj.roles !== null &&
                          obj.roles.map((role, index) => (
                            <RoleTag key={index} roleName={role} {...obj} />
                          ))}
                      </div>
                      {roles.filter(
                        (allRoles) =>
                          obj.roles !== null &&
                          !obj.roles.find((obj) => obj === allRoles)
                      ).length !== 0 && (
                        <MenuIco
                          onClick={() => openModal(obj)}
                          type={"button"}
                          data-bs-toggle={"modal"}
                          data-bs-target={"#addRoleModal"}
                          className={styles.tagsMenu}
                        />
                      )}
                    </div>
                  </td>
                  <td>
                    <div
                      data-type={"button"}
                      onClick={() => openModal(obj)}
                      data-bs-toggle={"modal"}
                      data-bs-target={"#banModal"}
                      className={
                        obj.disabled ? styles.banBlock : styles.noBanBlock
                      }
                    >
                      {obj.disabled ? "Ban" : "NoBan"}
                    </div>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td style={{ padding: "280px 0px" }} colSpan={4}>
                  Пользователи не найдены
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      {pagesAmounts <= 1 ? (
        " "
      ) : (
        <Pagination
          pageIndex={paginationNumber}
          pageCount={pagesAmounts}
          onChange={(index) => setPaginationNumber(index)}
        />
      )}
    </div>
  );
};

export default Users;
