import { Route, Routes, Navigate } from "react-router-dom";
import React from "react";
import { Toaster } from "react-hot-toast";
import { jwtDecode } from "jwt-decode";

import styles from "./index.scss";

import LoadingBar from "./components/LoadingBar";
import { JwtProps } from "./components/Sidebar";

import LoginPage from "./pages/LoginPage";
import AdminPanel from "./pages/AdminPanel";
import Users from "./pages/Users";
import Tournaments from "./pages/Tournaments";
import Settings from "./pages/Settings";
import DraftEditor from "./pages/DraftEditor";
import Drafts from "./pages/Drafts";
import OnReview from "./pages/OnReview";

import { selectAdminUser, setRoles, setUser } from "./store/adminUser/adminUserSlice";
import { useDispatch, useSelector } from "react-redux";
import Archive from "./pages/Archive";

function App() {
  const [loading, setLoading] = React.useState(true);
  const dispatch = useDispatch();
  const { user, roles } = useSelector(selectAdminUser);

  React.useEffect(() => {
    const value = localStorage.getItem("adminUser");
    if (typeof value === "string") {
      const adminUser = JSON.parse(value);
      dispatch(setUser(adminUser));
      const decoded = jwtDecode<JwtProps>(adminUser.access_token);
        dispatch(setRoles(decoded.roles));
    }
    setLoading(false);
  }, [dispatch]);

  return loading ? (
    <LoadingBar loading={loading} />
  ) : (
    <div className={styles.appBackground}>
      <Toaster />
      <Routes>
        <Route path="/" element={<Navigate replace to="/login" />}></Route>
        <Route
          path="/login"
          element={
            user === null ? (
              <LoginPage />
            ) : (
              <Navigate replace to="/adminPanel/drafts" />
            )
          }
        ></Route>
        <Route
          path="/adminPanel/*"
          element={
            user !== null ? <AdminPanel /> : <Navigate replace to="/login" />
          }
        >
          <Route path="*" element={<Navigate replace to="/login" />}></Route>
          <Route path="drafts" element={<Drafts />}></Route>
          <Route path="drafts/:id" element={<DraftEditor />}></Route>
          <Route
            path="review"
            element={
              roles.find(
                (obj) => obj === "ADMIN" || obj === "HEAD_EDITOR"
              ) ? (
                <OnReview />
              ) : (
                <Navigate replace to="/adminPanel/drafts" />
              )
            }
          ></Route>
          <Route
            path="archive"
            element={
              roles.find((obj) => obj === "ADMIN") ? (
                <Archive />
              ) : (
                <Navigate replace to="/adminPanel/drafts" />
              )
            }
          ></Route>
          <Route path="tournaments" element={<Tournaments />}></Route>
          <Route
            path="users"
            element={
              roles.find((obj) => obj === "ADMIN") ? (
                <Users />
              ) : (
                <Navigate replace to="/adminPanel/drafts" />
              )
            }
          ></Route>
          <Route path="settings" element={<Settings />}></Route>
        </Route>
        <Route path="*" element={<Navigate replace to="/login" />}></Route>
      </Routes>
    </div>
  );
}

export default App;
