import React, { ChangeEvent } from "react";

import styles from "./AddBlocksModal.module.scss";

import { ReactComponent as AddTextIco } from "../../assets/ico/addText.svg";
import { ReactComponent as AddQuoteIco } from "../../assets/ico/addQuote.svg";
import { ReactComponent as AddImageIco } from "../../assets/ico/addImage.svg";
import { ReactComponent as AddVideoIco } from "../../assets/ico/addVideo.svg";
import { ReactComponent as AddCarouselIco } from "../../assets/ico/addCarousel.svg";
import { ReactComponent as AddYouTubeIco } from "../../assets/ico/addYouTube.svg";
import { BlockProps } from "../../store/ownDrafts/ownDraftsSlice";
import toast from "react-hot-toast";
import { checkEmptyBlock } from "../../utils/checkEmptyBlock";

type AddBlocksModalProps = {
  index: number;
  createNewBlock: (index: number, type: string) => void;
  tools: React.RefObject<HTMLDivElement>;
  setShowBlocks: React.Dispatch<React.SetStateAction<boolean>>;
  blocksValues: BlockProps[];
  addMedia: (formData: FormData) => Promise<any>;
  setBlocksValues: React.Dispatch<React.SetStateAction<BlockProps[]>>;
  saveDraft: (newBlocks?: BlockProps[], bannerUrl?: string) => void;
};

const AddBlocksModal: React.FC<AddBlocksModalProps> = ({
  index,
  createNewBlock,
  tools,
  setShowBlocks,
  blocksValues,
  addMedia,
  setBlocksValues,
  saveDraft,
}) => {
  const addPhoto = async (event: ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;

    if (files === null) {
      return;
    }

    const formData = new FormData();
    formData.append("file", files[0]);

    const data = await addMedia(formData);

    if (!data) {
      return;
    }

    const newBlock = {
      type: "PICTURE",
      data: { type: data.media_type, url: data.public_url, alt: null },
    };

    const newItems = [
      ...blocksValues.slice(0, index + 1),
      newBlock,
      ...blocksValues.slice(index + 1),
    ];

    setBlocksValues(newItems);
    saveDraft(newItems);
  };

  const addVideo = async (event: ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;

    if (files === null) {
      return;
    }

    if (files[0].type !== "video/mp4") {
      toast.error("Пожалуйста, загрузите видеофайл!");
      return;
    }

    const formData = new FormData();
    formData.append("file", files[0]);

    const data = await addMedia(formData);

    if (!data) {
      return;
    }

    const newBlock = {
      type: "VIDEO",
      data: {
        type: data.media_type,
        url: data.public_url,
        provider: "CUSTOM",
      },
    };

    const newItems = [
      ...blocksValues.slice(0, index + 1),
      newBlock,
      ...blocksValues.slice(index + 1),
    ];

    setBlocksValues(newItems);
    saveDraft(newItems);
  };

  const addCarouselBlock = async (event: ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;

    if (files === null) {
      return;
    }

    const formData = new FormData();
    formData.append("file", files[0]);

    const data = await addMedia(formData);

    if (!data) {
      return;
    }

    const newBlock = {
      type: "PICTURE_CAROUSEL",
      data: [{ type: data.media_type, url: data.public_url, alt: null }],
    };

    const newItems = [
      ...blocksValues.slice(0, index + 1),
      newBlock,
      ...blocksValues.slice(index + 1),
    ];

    setBlocksValues(newItems);
    saveDraft(newItems);
  };

  const clickTextToolsButton = (value: string) => {
    createNewBlock(index, value);
    setShowBlocks(false);
  };

  const clickVideoButton = (event: React.ChangeEvent<HTMLInputElement>) => {
    const resp = checkEmptyBlock(blocksValues);

    if (!resp) {
      toast.error("Заполните все пустые поля!");
      return;
    }

    addVideo(event);
    setShowBlocks(false);
  };

  const clickPhotoButton = (event: React.ChangeEvent<HTMLInputElement>) => {
    const resp = checkEmptyBlock(blocksValues);

    if (!resp) {
      toast.error("Заполните все пустые поля!");
      return;
    }

    addPhoto(event);
    setShowBlocks(false);
  };

  const clickCarouselButton = (event: React.ChangeEvent<HTMLInputElement>) => {
    const resp = checkEmptyBlock(blocksValues);

    if (!resp) {
      toast.error("Заполните все пустые поля!");
      return;
    }

    addCarouselBlock(event);
    setShowBlocks(false);
  };

  return (
    <div ref={tools} className={styles.tools}>
      <div
        onClick={() => clickTextToolsButton("TEXT")}
        className={styles.toolBlock}
      >
        <AddTextIco />
        <div className={styles.blockTitle}>Текст</div>
      </div>
      <div
        onClick={() => clickTextToolsButton("QUOTE")}
        className={styles.toolBlock}
      >
        <AddQuoteIco />
        <div className={styles.blockTitle}>Цитата</div>
      </div>
      <label htmlFor={"addPhoto"} className={styles.toolBlock}>
        <AddImageIco />
        <div className={styles.blockTitle}>Фото</div>
      </label>
      <input
        onChange={(event) => clickPhotoButton(event)}
        className={styles.bannerFileInput}
        id={"addPhoto"}
        type="file"
      />
      <label htmlFor={"addVideo"} className={styles.toolBlock}>
        <AddVideoIco />
        <div className={styles.blockTitle}>Видео</div>
      </label>
      <input
        onChange={(event) => clickVideoButton(event)}
        className={styles.bannerFileInput}
        id={"addVideo"}
        type="file"
      />
      <label htmlFor={"addCarousel"} className={styles.toolBlock}>
        <AddCarouselIco />
        <div className={styles.blockTitle}>Карусель</div>
      </label>
      <input
        onChange={(event) => clickCarouselButton(event)}
        className={styles.bannerFileInput}
        id={"addCarousel"}
        type="file"
      />
      <div
        onClick={() => clickTextToolsButton("YOUTUBE")}
        className={styles.toolBlock}
      >
        <AddYouTubeIco />
        <div className={styles.blockTitle}>YouTube</div>
      </div>
    </div>
  );
};

export default AddBlocksModal;
