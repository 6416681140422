import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store";

const initialState = {
  roles: ["ADMIN", "WRITER", "PLAYER", "COACH", "HEAD_EDITOR"],
};

export const rolesSlice = createSlice({
  name: "roles",
  initialState,
  reducers: {
  },
});

export const selectRoles = (state: RootState) => state.rolesSlice;

export default rolesSlice.reducer;
