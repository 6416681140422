import qs from "qs";
import React from "react";
import axios from "axios";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

import styles from "./Drafts.module.scss";

import Input from "../../components/Input";
import NotFound from "../../components/NotFound";
import Pagination from "../../components/Pagination";
import LoadingBar from "../../components/LoadingBar";
import ArticleCard from "../../components/ArticleCard";
import DraftLoading from "../../components/DraftLoading";
import WarningModal from "../../components/WarningModal";
import DraftSettings from "../../components/DraftSettings";
import FilterParameters from "../../components/FilterParameters";

import { useAppDispatch } from "../../store/store";
import { selectAdminUser } from "../../store/adminUser/adminUserSlice";
import { toggleModal } from "../../store/sessionModal/sessionModalSlice";
import {
  fetchOwnDrafts,
  selectOwnDrafts,
} from "../../store/ownDrafts/ownDraftsSlice";
import { setEditorValue } from "../../store/editor/editorSlice";

interface QueryParams {
  articleOrder: string;
  articleStatus: string;
  paginationNumber: number;
  draftLanguage?: string;
  searchValue?: string;
}

interface QueryParams {
  articleOrder: string;
  articleStatus: string;
  paginationNumber: number;
  draftLanguage?: string;
  searchValue?: string;
}

const Drafts: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [sortValue, setSortValue] = React.useState("NEW");
  const [statusValue, setStatusValue] = React.useState("DRAFT");
  const [draftLanguage, setDraftLanguage] = React.useState("");
  const [paginationNumber, setPaginationNumber] = React.useState(0);
  const [searchValue, setSearchValue] = React.useState("");

  const isSearch = React.useRef(false);
  const isMounted = React.useRef(false);

  const [showSettings, setShowSettings] = React.useState(false);
  const [isAlert, setIsAlert] = React.useState(false);
  const [draftId, setDraftId] = React.useState("");
  const [isLoading, setIsLoading] = React.useState(false);
  const [inputValue, setInputValue] = React.useState("");

  const { ownDrafts, status, pagesAmounts, ownDraftsSize } =
    useSelector(selectOwnDrafts);
  const { user: adminUser } = useSelector(selectAdminUser);
  const token = adminUser !== null ? adminUser.access_token : "";

  React.useEffect(() => {
    if (status === "success") {
      if (pagesAmounts < paginationNumber + 1) {
        setPaginationNumber(0);
      }
    }
  }, [status, pagesAmounts, paginationNumber]);

  React.useEffect(() => {
    if (isMounted.current) {
      const params: QueryParams = {
        articleOrder: sortValue,
        articleStatus: statusValue,
        paginationNumber,
      };

      if(draftLanguage.length !== 0){
        params.draftLanguage = draftLanguage; 
      }

      if(searchValue.length !== 0){
        params.searchValue = searchValue; 
      }

      const queryString = qs.stringify(params);

      navigate(`?${queryString}`);
    }

    isMounted.current = true;
  }, [
    sortValue,
    statusValue,
    draftLanguage,
    paginationNumber,
    searchValue,
    navigate,
  ]);

  React.useEffect(() => {
    if (window.location.search) {
      const params = qs.parse(window.location.search.substring(1));

      console.log(params)

      setSortValue(String(params.articleOrder));
      setStatusValue(String(params.articleStatus));
      setPaginationNumber(Number(params.paginationNumber));

      if(params.draftLanguage !== undefined){
        setDraftLanguage(String(params.draftLanguage));
      }

      if(params.searchValue !== undefined){
        setSearchValue(String(params.searchValue));
      }

      setInputValue(
        typeof params.searchValue === "string" ? params.searchValue : ""
      );

      isSearch.current = true;
    }
  }, []);

  React.useEffect(() => {
    window.scrollTo(0, 0);

    if (!isSearch.current) {
      if (!window.location.search) {
        setSortValue("NEW");
        setStatusValue("DRAFT");
        setDraftLanguage("");
        setPaginationNumber(0);
        setSearchValue("");
      }

      dispatch(
        fetchOwnDrafts({
          token,
          articleOrder: sortValue,
          articleStatus: statusValue,
          articleLanguage: draftLanguage,
          searchValue,
          pagination: paginationNumber,
        })
      );
    }

    isSearch.current = false;
  }, [
    dispatch,
    token,
    sortValue,
    statusValue,
    draftLanguage,
    searchValue,
    paginationNumber,
  ]);

  React.useEffect(() => {
    if (status === "error") {
      dispatch(toggleModal(true));
    }
  }, [status, dispatch]);

  const onClickCreate = async () => {
    try {
      setIsLoading(true);
      const {data} = await axios.post(
        "/api/v1/admin/draft",
        {},
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      );
      dispatch(
        fetchOwnDrafts({
          token,
          articleOrder: sortValue,
          articleStatus: statusValue,
          articleLanguage: draftLanguage,
          searchValue,
          pagination: paginationNumber,
        })
      );
      setIsLoading(false);
      dispatch(
        setEditorValue({
          id: data.id,
          title: "",
          banner_url: "",
          tags: [],
          blocks: [],
          lang: null,
          slug: '',
        })
      );
      setShowSettings(true);
    } catch (err) {
      console.log(err);
      toast.error("");
    }
  };

  const confirmDeleteDraft = async () => {
    try {
      setIsAlert(false);
      await axios.delete(`/api/v1/admin/articles/draft/${draftId}`, {
        headers: {
          Authorization: "Bearer " + token,
        },
      });
      setDraftId("");
      dispatch(
        fetchOwnDrafts({
          token,
          articleOrder: sortValue,
          articleStatus: statusValue,
          articleLanguage: draftLanguage,
          searchValue,
          pagination: ownDrafts.length === 1 ? 0 : paginationNumber,
        })
      );
      toast.success("Черновик успешно удален!");
    } catch (err) {
      console.log(err);
      toast.error(
        "Вы не можете удалить этот черновик! В нём присутсвует контент."
      );
      setIsAlert(false);
    }
  };

  if (isLoading) {
    <LoadingBar loading={isLoading} />;
  }

  return isLoading && ownDraftsSize === null ? (
    <LoadingBar loading={isLoading} />
  ) : (
    <>
      {isAlert ? (
        <Modal
          data-bs-theme="light"
          show={isAlert}
          onHide={() => setIsAlert(false)}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title>Предупреждение</Modal.Title>
          </Modal.Header>
          <Modal.Body>Вы уверены что хотите удалить эту статью?</Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => confirmDeleteDraft()}>
              Удалить
            </Button>
          </Modal.Footer>
        </Modal>
      ) : (
        ""
      )}

      {showSettings && <DraftSettings setShowSettings={setShowSettings} ownDrafts={ownDrafts} sortValue={sortValue} statusValue={statusValue} draftLanguage={draftLanguage} paginationNumber={paginationNumber} searchValue={searchValue}/>}

      <div className={styles.backgroundArticles}>
        <div className={styles.draftsHeader}>
          <h1> Мои статьи </h1>
          <Input
            inputValue={inputValue}
            setInputValue={setInputValue}
            setSearchValue={setSearchValue}
            setPaginationNumber={setPaginationNumber}
          />
          <div className={styles.createButton} onClick={onClickCreate}>
            <div>+</div>
          </div>
        </div>

        <FilterParameters
          orderValue={sortValue}
          statusValue={statusValue}
          languageValue={draftLanguage}
          setSortValue={setSortValue}
          setStatusValue={setStatusValue}
          setPaginationNumber={setPaginationNumber}
          setArticleLanguage={setDraftLanguage}
        />

        <div className={styles.articlesList}>
          {status === "loading" ? (
            <DraftLoading length={10} />
          ) : status === "success" ? (
            ownDraftsSize === 0 ? (
              <NotFound
                error={"Упс!"}
                text={"Статьи с такими фильтрами не были найдены"}
                ico={"findError"}
              />
            ) : (
              ownDrafts.map((obj) => (
                <ArticleCard
                  drafts={ownDrafts}
                  setShowSettings={setShowSettings}
                  setDraftId={setDraftId}
                  setIsAlert={setIsAlert}
                  setIsLoading={setIsLoading}
                  key={obj.id}
                  pagination={paginationNumber}
                  {...obj}
                />
              ))
            )
          ) : (
            status === "error" && <WarningModal />
          )}
        </div>
        {pagesAmounts <= 1 ? (
          " "
        ) : (
          <Pagination
            pageIndex={paginationNumber}
            pageCount={pagesAmounts}
            onChange={(index) => setPaginationNumber(index)}
          />
        )}
      </div>
    </>
  );
};

export default Drafts;
