import React from "react";
import PhoneInput from "react-phone-input-2";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { toast } from "react-hot-toast";
import axios from "axios";

import "react-phone-input-2/lib/style.css";
import styles from "./LoginPage.module.scss";

import { setPhone, setPass, selectLogin } from "../../store/login/loginSlice";
import { setRoles, setUser } from "../../store/adminUser/adminUserSlice";
import { useAppDispatch } from "../../store/store";
import { ClipLoader } from "react-spinners";
import { Button } from "react-bootstrap";
import { jwtDecode } from "jwt-decode";
import { JwtProps } from "../../components/Sidebar";

const LoginPage: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [isLoading, setIsLoading] = React.useState(false);

  const { pass, phoneValue } = useSelector(selectLogin);

  const onClickLogin = async () => {
    setIsLoading(true);
    await axios
      .post("/api/v1/admin/login", {
        phone: "+" + phoneValue,
        password: pass,
      })
      .then((resp) => {
        toast.success("Вы вошли в систему");
        dispatch(setUser(resp.data));
        const decoded = jwtDecode<JwtProps>(resp.data.access_token);
        dispatch(setRoles(decoded.roles));
        navigate("/adminPanel/drafts");
        localStorage.clear();
        localStorage.setItem("adminUser", JSON.stringify(resp.data));
      })
      .catch((err) => {
        if (err.response.status === 422) {
          toast.error("Пароль должен соответсвовать требованиям!");
        } else if (err.response.status === 401) {
          toast.error("Не верный пароль!");
        } else {
          toast.error("Что-то сломалось, попробуйте позже :(");
        }
        console.log(err);
      });
    setIsLoading(false);
  };

  return (
    <div className={styles.backgroundLogin}>
      <h4>Вход в админ панель</h4>
      <div className="mb-3">
        <p>Phone</p>
        <PhoneInput
          country={"in"}
          value={phoneValue}
          onChange={(phone) => dispatch(setPhone(phone))}
        />
      </div>
      <div className="mb-3">
        <p>Password</p>
        <input
          type="password"
          className="form-control"
          value={pass}
          onChange={(e) => dispatch(setPass(e.target.value))}
        />
      </div>

      <Button
        className={styles.cardButton}
        onClick={() => onClickLogin()}
        variant="primary"
        disabled={isLoading ? true : false}
      >
        {isLoading ? <ClipLoader color="white" /> : "Войти"}
      </Button>
    </div>
  );
};

export default LoginPage;
