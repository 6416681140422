import { BlockProps, QuoteBlockProps, VideoBlockProps } from "../store/ownDrafts/ownDraftsSlice";

export const checkEmptyBlock = (blocksValues: BlockProps[]) => {
    for (const obj of blocksValues) {
        if (obj?.type === "TEXT") {
          if ((obj.data as string).length === 0) {
            return false;
          }
        } else if (obj?.type === "QUOTE") {
          if (
            (obj.data as QuoteBlockProps).author_name.length === 0 ||
            (obj.data as QuoteBlockProps).link.length === 0 ||
            (obj.data as QuoteBlockProps).text.length === 0
          ) {
            return false;
          }
        } else if (obj?.type === "VIDEO") {
          if ((obj.data as VideoBlockProps).url.length === 0) {
            return false;
          }
        }
      }
      return true;
}