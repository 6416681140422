import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { RootState } from "../store";

type FetchUsersProps = {
  token: string,
  paginationNumber: number;
  searchValue?: string;
}

export const fetchUsers = createAsyncThunk<DataProps, FetchUsersProps>(
  "users/fetchUsers",
  async (params) => {
    const { token, paginationNumber, searchValue} = params;
    const { data } = await axios.get<DataProps>(
      `/api/v1/admin/users?${`limit=11&skip=${11 * paginationNumber}`}${
        searchValue ? `&nickname_or_phone=${searchValue}` : ""
      }`,
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );
    return data;
  }
);

export type UserProps = {
  id: string,
  nickname: string,
  phone: string,
  disabled: boolean,
  roles: string[] | null
}

type DataProps = {
  items: UserProps[],
  amount_of_pages: number,
  items_size: string,
}

interface UsersSliceState {
  users: UserProps[] | [],
  status: "loading" | "success" | "error",
  currentUser: UserProps | null,
  pagesAmounts: number,
  allUsersSize: string,
}


const initialState: UsersSliceState  = {
  users: [],
  status: 'loading',
  currentUser: null,
  pagesAmounts: 1,
  allUsersSize: '',
};

export const usersSlice = createSlice({
  name: "users",
  initialState,
  reducers: {
    setCurrentUser: (state, action) => {
      state.currentUser = action.payload;
    },
    clearCurrentUser: (state) => {
      state.currentUser = null;
    },
    setUsers: (state, action) => {
      state.users = action.payload;
    },
    setDeleteRole: (state, action) => {
      const currentUser = state.users.find((obj) => obj.id === action.payload.id);
      if(!currentUser){
        return;
      }
      const roles = currentUser.roles !== null ? currentUser.roles.filter((obj) => obj !== action.payload.roleName) : [];
      const newState = state.users.map((obj) => {
        if (obj.id === action.payload.id) {
          return { ...obj, roles };
        }
        return obj;
      });
      state.users = newState;
    },
    setAddRole: (state, action) => {
      const current = state.currentUser;
      if(current === null){
        return;
      }
      let roles = [] as string[];
      if(current.roles !== null){
        roles = current.roles;
      }
      roles.push(action.payload);
      const newState = state.users.map((obj) => {
        if (obj.id === current.id) {
          state.currentUser = { ...obj, roles };
          return { ...obj, roles };
        }
        return obj;
      });
      state.users = newState;
    },
    changeUserStatus: (state) => {
      const current = state.currentUser;
      if(current === null){
        return;
      }
      const newState = state.users.map((obj) => {
        if(obj.id === current.id){
          return {...obj, disabled: !obj.disabled}
        }
        return obj;
      });
      state.users = newState;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchUsers.pending, (state) => {
        state.status = 'loading';
        state.users = [];
    });
    builder.addCase(fetchUsers.fulfilled, (state, action) => {
        state.status = 'success';
        state.users = action.payload.items;
        state.pagesAmounts = action.payload.amount_of_pages;
        state.allUsersSize = action.payload.items_size;
    });
    builder.addCase(fetchUsers.rejected, (state, action) => {
        state.status = 'error';
        state.users = [];
    })
},
});

export const selectUsers = (state: RootState) => state.usersSlice;

export const {
  setCurrentUser,
  clearCurrentUser,
  setUsers,
  setDeleteRole,
  setAddRole,
  changeUserStatus
} = usersSlice.actions;

export default usersSlice.reducer;
