import React from "react";

import styles from "./Tag.module.scss";

type TagProps = {
  id?: string,
  name: string,
  onClickDeleteFunction?: (id: string) => void,
  onClickFunction?: (name: string) => void
};

const Tag: React.FC<TagProps> = ({ id, name, onClickDeleteFunction, onClickFunction }) => {
  return (
    <div className={styles.tagBackground}>
      <div onClick={() => onClickFunction && onClickFunction(name)}>{name}</div>
      {onClickDeleteFunction &&
      <div
        onClick={() => onClickDeleteFunction(id ? id : name)}
        className={styles.deleteButton}
      >
        x
      </div>}
    </div>
  );
};

export default Tag;
