import React from "react";

import styles from "./PictureBlock.module.scss";

type PictureBlockProps = {
  index: number;
  src: string;
  altValue: string | null;
  updateInputValue: (idx: number, value: string) => void;
};

const PictureBlock: React.FC<PictureBlockProps> = ({index, src, altValue, updateInputValue }) => {
  return (
    <div className={styles.pictureBlock}>
      <img src={src} alt={altValue ? altValue : ""} />
      <div className={styles.pictureAlt}>
        <input
          type="text"
          placeholder="Описание..."
          value={altValue ? altValue : ""}
          onChange={(event) => updateInputValue(index, event.target.value)}
        />
      </div>
    </div>
  );
};

export default PictureBlock;
