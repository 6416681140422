import React from "react";
import TextareaAutosize from "react-textarea-autosize";

import { ReactComponent as PersonIco } from "../../../assets/ico/person.svg";
import { ReactComponent as LinkIco } from "../../../assets/ico/link.svg";

import styles from "./QuoteBlock.module.scss";

type QuoteBlockProps = {
    index: number,
    quoteValue: string,
    authorName: string,
    linkValue: string,
    updateInputValue: (index: number, value: string, type: string) => void
}

const QuoteBlock: React.FC<QuoteBlockProps> = ({index, quoteValue, authorName, linkValue, updateInputValue}) => {
  return (
    <div className={styles.quoteBlock}>
      <TextareaAutosize
        className={styles.quoteTextArea}
        placeholder={"Введите цитату"}
        value={quoteValue}
        onChange={(event) =>
          updateInputValue(index, event.target.value, "text")
        }
      />
      <div className={styles.sourcesBlock}>
        <div className={styles.quoteLine}>
          <PersonIco />
          <input
            className={styles.quoteInput}
            value={authorName}
            onChange={(event) =>
              updateInputValue(index, event.target.value, "author_name")
            }
          />
        </div>
        <div className={styles.quoteLine}>
          <LinkIco />
          <input
            className={styles.quoteInput}
            value={linkValue}
            onChange={(event) =>
              updateInputValue(index, event.target.value, "link")
            }
          />
        </div>
      </div>
    </div>
  );
};

export default QuoteBlock;