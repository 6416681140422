import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { DraftProps } from "../ownDrafts/ownDraftsSlice";
import { RootState } from "../store";

export const fetchOneDraft = createAsyncThunk<DraftProps, Record<string, string>>(
  "oneDraft/fetchOneDraft",
  async (params) => {
    const { token, id} = params;
    const { data } = await axios.get<DraftProps>(
      `/api/v1/admin/article/${id}`,
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );
    return data;
  }
);

interface AllDraftsSliceState {
    oneDraft: DraftProps | null,
    status: "loading" | "success" | "error",
  }

const initialState: AllDraftsSliceState  = {
    oneDraft: null,
    status: "loading"
  };

export const oneDraftSlice = createSlice({
  name: "oneDrafts",
  initialState,
  reducers: {
    clearDraft: (state, action) => {
      state.oneDraft = null;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchOneDraft.pending, (state) => {
        state.status = 'loading';
        state.oneDraft = null;
    });
    builder.addCase(fetchOneDraft.fulfilled, (state, action) => {
        state.status = 'success';
        state.oneDraft = action.payload;
    });
    builder.addCase(fetchOneDraft.rejected, (state, action) => {
        state.status = 'error';
        state.oneDraft = null;
    })
},
});

export const selectOneDraft = (state: RootState) => state.oneDraftSlice;

export const {
    clearDraft
} = oneDraftSlice.actions;

export default oneDraftSlice.reducer;