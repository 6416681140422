import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store";

const initialState = {
  phoneValue: "",
  pass: "",
};

export const loginSlice = createSlice({
  name: "login",
  initialState,
  reducers: {
    setPass: (state, action) => {
      state.pass = action.payload;
    },
    setPhone: (state, action) => {
      state.phoneValue = action.payload;
    },
  },
});

export const selectLogin = (state: RootState) => state.loginSlice;

export const { setPhone, setPass } = loginSlice.actions;

export default loginSlice.reducer;
