import React from "react";
import {  useSelector } from "react-redux";
import { useAppDispatch } from "../../store/store";

import styles from "./RoleTag.module.scss";

import { selectUsers, setDeleteRole } from "../../store/users/usersSlice";
import axios from "axios";
import { selectAdminUser } from "../../store/adminUser/adminUserSlice";

type RoleTagProps = {
  roleName: string,
  id: string
}

const RoleTag: React.FC<RoleTagProps> = ({ roleName, id }) => {
  const dispatch = useAppDispatch();
  const {users} = useSelector(selectUsers);
  const { user: adminUser } = useSelector(selectAdminUser);
  const token = adminUser !== null ? adminUser.access_token : "";

  const onClickDelete = async () => {
    const currentUser = users.find((obj) => obj.id === id);
    if(!currentUser){
      return;
    }
    const currentRoles = currentUser.roles?.filter(obj => obj !== roleName);
    dispatch(setDeleteRole({ roleName, id }));
    await axios.patch(
      `/api/v1/admin/user/${id}/roles`,
      currentRoles,
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );
  };

  return (
    <div className={styles.tagBackground}>
      {roleName}
      <div
        onClick={() => onClickDelete()}
        className={styles.deleteButton}
      >
        x
      </div>
    </div>
  );
};

export default RoleTag;
