import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";

import { store } from './store/store';
import { Provider } from 'react-redux'

import "./index.scss";

import App from "./App";

document.body.classList.add("dark")
const rootElem = document.getElementById("root")

if(rootElem){
  const root = ReactDOM.createRoot(rootElem);
root.render(
  <BrowserRouter>
  <Provider store={store}>
    <App />
    </Provider>
  </BrowserRouter>
);
}
