import { createSlice } from "@reduxjs/toolkit";
import { BlockProps } from "../ownDrafts/ownDraftsSlice";
import { RootState } from "../store";

interface EditorSliceState {
  id: string;
  blocks: BlockProps[];
  title: string;
  banner_url: string;
  tags: [] | string[];
  lang: string;
  slug: string;
}

const initialState: EditorSliceState = {
  id: "",
  blocks: [],
  title: "",
  banner_url: "",
  tags: [],
  lang: "",
  slug: "",
};

export const editorSlice = createSlice({
  name: "editor",
  initialState,
  reducers: {
    deleteEditorValue: (state) => {
      state.id = "";
      state.blocks = [];
      state.title = "";
      state.banner_url = "";
      state.tags = [];
      state.lang = "RU";
      state.slug = "";
    },
    setEditorValue: (state, action) => {
      state.id = action.payload.id;
      if (action.payload.blocks.length !== 0) {
        state.blocks = action.payload.blocks;
      } else {
        state.blocks = [];
      }
      state.title = action.payload.title;
      state.banner_url = action.payload.banner_url;
      state.tags = action.payload.tags;
      state.lang = action.payload.lang;
      state.slug = action.payload.slug;
    },
  },
});

export const selectEditorValues = (state: RootState) => state.editorSlice;

export const { deleteEditorValue, setEditorValue } = editorSlice.actions;

export default editorSlice.reducer;
