import React from "react";

import styles from "./VideoBlock.module.scss";

type VideoBlockProps = {
    value: string
};

const VideoBlock: React.FC<VideoBlockProps> = ({value}) => {
  return (
    <div className={styles.videoBlock}>
      <video controls width="250">
        <source src={value} />
      </video>
    </div>
  );
};

export default VideoBlock;
