import React from "react";
import { Skeleton } from "@mui/material";
import styles from "../../pages/Users/Users.module.scss";

type TableRowsLoaderProps = {
  rowsNum: number;
};

const TableRowsLoader: React.FC<TableRowsLoaderProps> = ({ rowsNum }) => {
  return rowsNum ? (
    <>
      {[...Array(rowsNum)].map((_,index) => (
        <tr key={index}>
          <td className={styles.firstColumn}>
            <Skeleton animation="wave" variant="text" />
          </td>
          <td>
            <Skeleton animation="wave" variant="text" />
          </td>
          <td>
            <Skeleton animation="wave" variant="text" />
          </td>
          <td>
            <Skeleton animation="wave" variant="text" />
          </td>
        </tr>
      ))}
    </>
  ) : <></>;
};

export default TableRowsLoader;
