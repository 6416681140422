import React from "react";
import ReactQuill from "react-quill";
import parse from "html-react-parser";

import "react-quill/dist/quill.snow.css";

import styles from "./TextBlock.module.scss";

type TextBlockProps = {
  updateInputValue: (index: number, value: string) => void;
  value: string;
  index: number;
};

const TextBlock: React.FC<TextBlockProps> = ({
  value,
  index,
  updateInputValue,
}) => {

  const [isEdit, setIsEdit] = React.useState(false);

  const modules = {
    toolbar: [
      [{ header: [1, 2, 3, false] }],
      ["bold", "italic", "underline", "strike"],
      [{ list: "ordered" }, { list: "bullet" }],
      ["link"],
    ],
  };

  const jsxElements = parse(value);

  const editor = React.useRef(null);
  const text = React.useRef(null);

  React.useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (editor.current && !event.composedPath().includes(editor.current)) {
        setIsEdit(false);
      }
    };

    document.body.addEventListener("click", handleClickOutside);

    return () => document.body.removeEventListener("click", handleClickOutside);
  });

  const updateTextValue = (str: string) => {
    updateInputValue(index, str);
  };

  return (
    <div className={styles.textBlock}>
      {isEdit ? (
        <div className={styles.textEditor} ref={editor}>
          <ReactQuill
            theme="snow"
            value={value}
            modules={modules}
            onChange={(string) => updateTextValue(string)}
          />
        </div>
      ) : value.length === 0 || value === "<p><br></p>" ? (
        <div
          className={styles.textValue}
          ref={text}
          onClick={() => setIsEdit(true)}
        >
          <span>Начните вводить текст...</span>
        </div>
      ) : (
        <div
          className={styles.textValue}
          ref={text}
          onClick={() => setIsEdit(true)}
        >
          {jsxElements}
        </div>
      )}
    </div>
  );
};

export default TextBlock;
