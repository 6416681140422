import React from "react";
import qs from "qs";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import styles from "./OnReview.module.scss";

import Input from "../../components/Input";
import NotFound from "../../components/NotFound";
import LoadingBar from "../../components/LoadingBar";
import Pagination from "../../components/Pagination";
import ArticleCard from "../../components/ArticleCard";
import DraftLoading from "../../components/DraftLoading";
import WarningModal from "../../components/WarningModal";
import FilterParameters from "../../components/FilterParameters";

import { useAppDispatch } from "../../store/store";
import { selectAdminUser } from "../../store/adminUser/adminUserSlice";
import { toggleModal } from "../../store/sessionModal/sessionModalSlice";
import {
  fetchAllDrafts,
  selectAllDrafts,
} from "../../store/allDrafts/allDraftsSlice";

const OnReview: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [sortValue, setSortValue] = React.useState("NEW");
  const [reviewLanguage, setReviewLanguage] = React.useState('');
  const [paginationNumber, setPaginationNumber] = React.useState(0);
  const [searchValue, setSearchValue] = React.useState('');
  const articleStatus = "REVIEW";

  const isSearch = React.useRef(false);
  const isMounted = React.useRef(false);

  const { allDrafts, status, pagesAmounts, allDraftsSize } =
    useSelector(selectAllDrafts);
  const { user: adminUser } = useSelector(selectAdminUser);
  const token = adminUser !== null ? adminUser.access_token : "";

  const [isLoading, setIsLoading] = React.useState(false);
  const [inputValue, setInputValue] = React.useState("");

  React.useEffect(() => {
    if (status === "success") {
      if (pagesAmounts < paginationNumber + 1) {
        setPaginationNumber(0);
      }
    }
  }, [status, pagesAmounts, paginationNumber]);

  React.useEffect(() => {
    if (isMounted.current) {
      const queryString = qs.stringify({
        articleOrder: sortValue,
        reviewLanguage,
        paginationNumber,
        searchValue,
      });

      navigate(`?${queryString}`);
    }

    isMounted.current = true;
  }, [sortValue, reviewLanguage, paginationNumber, searchValue, navigate]);

  React.useEffect(() => {
    if (window.location.search) {
      const params = qs.parse(window.location.search.substring(1));

      setSortValue(String(params.articleOrder));
      setReviewLanguage(String(params.reviewLanguage));
      setPaginationNumber(Number(params.paginationNumber));
      setSearchValue(String(params.searchValue));

      setInputValue(
        typeof params.searchValue === "string" ? params.searchValue : ""
      );

      isSearch.current = true;
    }
  }, []);

  React.useEffect(() => {
    window.scrollTo(0, 0);

    if (!isSearch.current) {
      if(!window.location.search){
        setSortValue("NEW");
        setReviewLanguage("");
        setPaginationNumber(0);
        setSearchValue('');
      }

      dispatch(
        fetchAllDrafts({
          token,
          articleOrder: sortValue,
          articleStatus,
          articleLanguage: reviewLanguage,
          searchValue,
          pagination: paginationNumber,
        })
      );
    }

    isSearch.current = false;
  }, [
    dispatch,
    token,
    sortValue,
    reviewLanguage,
    searchValue,
    paginationNumber,
  ]);

  React.useEffect(() => {
    if (status === "error") {
      dispatch(toggleModal(true));
    }
  }, [status, dispatch]);

  return isLoading ? (
    <LoadingBar loading={isLoading} />
  ) : (
    <div className={styles.backgroundReviewPage}>
      <div className={styles.archiveHeader}>
        <h1> Ревью </h1>
        <Input
          inputValue={inputValue}
          setInputValue={setInputValue}
          setSearchValue={setSearchValue}
          setPaginationNumber={setPaginationNumber}
        />
      </div>

      <FilterParameters
        orderValue={sortValue}
        languageValue={reviewLanguage}
        setSortValue={setSortValue}
        setArticleLanguage={setReviewLanguage}
        setPaginationNumber={setPaginationNumber}
      />

      <div className={styles.reviewDrafts}>
        {status === "loading" ? (
          <DraftLoading length={10} />
        ) : status === "success" ? (
          allDraftsSize === 0 ? (
            <NotFound
              error={"Упс!"}
              text={"Статьи с такими фильтрами не были найдены"}
              ico={"findError"}
            />
          ) : (
            allDrafts.map((obj) => (
              <ArticleCard
                drafts={allDrafts}
                pagination={paginationNumber}
                setIsLoading={setIsLoading}
                key={obj.id}
                {...obj}
              />
            ))
          )
        ) : (
          status === "error" && <WarningModal />
        )}
      </div>

      {pagesAmounts <= 1 ? (
        " "
      ) : (
        <Pagination
          pageIndex={paginationNumber}
          pageCount={pagesAmounts}
          onChange={(index) => setPaginationNumber(index)}
        />
      )}
    </div>
  );
};

export default OnReview;
