import React from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import toast from "react-hot-toast";
import { jwtDecode } from "jwt-decode";
import axios from "axios";
import { htmlToText } from "html-to-text";

import draftIco from "../../assets/ico/draft.png";
import publishedIco from "../../assets/ico/published.png";
import archivedIco from "../../assets/ico/archived.png";
import inReviewIco from "../../assets/ico/review.png";

import styles from "./ArticleCard.module.scss";

import Button from "react-bootstrap/Button";
import Badge from "react-bootstrap/Badge";

import { ReactComponent as BannerFileIco } from "../../assets/ico/bannerFile.svg";
import { ReactComponent as EditDraftIco } from "../../assets/ico/editDraft.svg";
import { ReactComponent as ArchiveDraftIco } from "../../assets/ico/archive.svg";
import { ReactComponent as DeleteDraftIco } from "../../assets/ico/trash.svg";
import { ReactComponent as WatchDraftIco } from "../../assets/ico/eye.svg";
import { ReactComponent as ReviewDraftIco } from "../../assets/ico/review.svg";
import { ReactComponent as DraftSettingIco } from "../../assets/ico/draftSettings.svg";

import { JwtProps } from "../Sidebar";

import { useAppDispatch } from "../../store/store";
import {
  BlockProps,
  DraftProps,
  fetchOwnDrafts,
} from "../../store/ownDrafts/ownDraftsSlice";
import { setEditorValue } from "../../store/editor/editorSlice";
import { selectAdminUser } from "../../store/adminUser/adminUserSlice";
import { fetchAllDrafts } from "../../store/allDrafts/allDraftsSlice";

type ArticleCardProps = {
  id: string;
  drafts: [] | DraftProps[];
  title: string;
  banner_url: string;
  tags: [] | string[];
  status: string;
  blocks: [] | BlockProps[];
  lang: string;
  slug: string;
  setIsAlert?: React.Dispatch<React.SetStateAction<boolean>>;
  setDraftId?: React.Dispatch<React.SetStateAction<string>>;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  setShowSettings?: React.Dispatch<React.SetStateAction<boolean>>;
  pagination: number;
};

type draftTypeOptions = {
  [key: string]: string;
};

const ArticleCard: React.FC<ArticleCardProps> = ({
  id,
  drafts,
  title = "",
  banner_url = "",
  tags = [],
  status,
  blocks = [],
  lang = "",
  slug = "",
  setIsAlert,
  setDraftId,
  setIsLoading,
  setShowSettings,
  pagination,
}) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { user: adminUser } = useSelector(selectAdminUser);
  const token = adminUser !== null ? adminUser.access_token : "";
  const decoded = jwtDecode<JwtProps>(
    adminUser !== null ? adminUser.access_token : ""
  );

  const path = window.location.pathname.split("/")[2];

  const blocksValue = blocks;

  const draftType: draftTypeOptions = {
    DRAFT: draftIco,
    PUBLISHED: publishedIco,
    ARCHIVED: archivedIco,
    REVIEW: inReviewIco,
  };

  const onClickDelete = () => {
    setIsAlert && setIsAlert(true);
    setDraftId && setDraftId(id);
  };

  const onClickEdit = () => {
    dispatch(setEditorValue({ title, banner_url, tags, blocks, lang }));
    navigate(`${id}`);
  };

  const archiveDraft = async () => {
    setIsLoading(true);
    await axios
      .patch(
        `/api/v1/admin/articles/draft/${id}/archive`,
        {},
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      )
      .then(() => {
        dispatch(
          fetchAllDrafts({
            token,
            articleStatus: "PUBLISHED",
            pagination: drafts.length === 1 ? 0 : pagination,
          })
        );
        toast.success("Черновик отправлен в архив!");
      })
      .catch((err) => {
        console.log(err);
        toast.error(
          "При отправке черновика в архив произошла ошибка! Попробуйте позже."
        );
      });
    setIsLoading(false);
  };

  const inReview = async () => {
    setIsLoading(true);
    await axios
      .patch(
        `/api/v1/admin/articles/draft/${id}/review`,
        {},
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      )
      .then(() => {
        dispatch(
          fetchOwnDrafts({
            token,
            articleStatus: "DRAFT",
            pagination: drafts.length === 1 ? 0 : pagination,
          })
        );
        toast.success("Черновик отправлен на проверку!");
      })
      .catch((err) => {
        console.log(err);
        toast.error(
          "При отправке черновика на проверку произошла ошибка! Попробуйте позже."
        );
      });
    setIsLoading(false);
  };

  const onClickBackToDraft = async () => {
    await axios
      .patch(
        `/api/v1/admin/articles/draft/${id}/back-to-draft`,
        {},
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      )
      .then(() => {
        if (
          path === "drafts" &&
          decoded.roles.find((obj) => obj === "WRITER")
        ) {
          dispatch(
            fetchOwnDrafts({
              token,
              articleStatus: "REVIEW",
              pagination: drafts.length === 1 ? 0 : pagination,
            })
          );
        } else {
          dispatch(
            fetchAllDrafts({
              token,
              articleStatus: "REVIEW",
              pagination: drafts.length === 1 ? 0 : pagination,
            })
          );
        }
        toast.success("Статья отправлена в черновики!");
      })
      .catch((err) => {
        console.log(err);
        toast.error("Ошибка при отправке на редактирование! Попробуйте позже.");
      });
  };

  const onClickPublish = async () => {
    await axios
      .patch(
        `/api/v1/admin/articles/${id}/publish`,
        {},
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      )
      .then(() => {
        dispatch(
          fetchAllDrafts({
            token,
            articleStatus: "REVIEW",
            pagination: drafts.length === 1 ? 0 : pagination,
          })
        );
        toast.success("Статья опубликована успешно!");
      })
      .catch((err) => {
        console.log(err);
        toast.error("Ошибка при публикации статьи! Попробуйте позже.");
      });
  };

  const onClickSettings = () => {
    setShowSettings && setShowSettings(true);
    dispatch(
      setEditorValue({
        id,
        title,
        banner_url,
        tags,
        blocks,
        lang,
        slug,
      })
    );
  };

  const parseValue =
    (blocksValue &&
      (blocksValue.find((obj) => obj.type === "TEXT")?.data as string)) ||
    "";
  const textWithoutTags = parseValue.length === 0 ? "" : htmlToText(parseValue);

  return (
    <>
      <div className={styles.backgroundArticleCard}>
        <div className={styles.draftHead}>
          <div className={styles.statusBackground}>
            <img src={draftType[status]} alt="Статус" />
          </div>
          <div onClick={() => onClickEdit()} className={styles.editDraftIco}>
            {status === "DRAFT" ? (
              banner_url && <EditDraftIco />
            ) : (
              <WatchDraftIco className={styles.watchDraftIco} />
            )}
          </div>
        </div>
        <div className={styles.cardImg}>
          {banner_url ? (
            <div style={{ backgroundImage: "url(" + banner_url + ")" }}></div>
          ) : (
            <BannerFileIco />
          )}
        </div>
        <div className={styles.cardBody}>
          <div className={styles.cardTitle}>
            <div className={styles.titleContent}>
              <h4>{title}</h4>
              <Badge bg="secondary">{lang}</Badge>
            </div>
            {path === "drafts" && (
              <div className={styles.draftSettingsIco}>
                <DraftSettingIco onClick={() => onClickSettings()} />
              </div>
            )}
          </div>
          <div className={styles.cardText}>{textWithoutTags}</div>
        </div>
        <div className={styles.cardButtons}>
          {path === "drafts" && status === "DRAFT" && (
            <Button
              className={styles.cardButton}
              onClick={() => inReview()}
              variant="success"
              disabled={
                status === "DRAFT" && banner_url.length !== 0 ? false : true
              }
            >
              Отправить на ревью
            </Button>
          )}
          {path === "review" &&
            status === "REVIEW" &&
            decoded.roles.find(
              (obj) => obj === "HEAD_EDITOR" || obj === "ADMIN"
            ) && (
              <Button
                className={styles.cardButton}
                onClick={() => onClickPublish()}
                variant="success"
              >
                Опубликовать
              </Button>
            )}

          {(path === "archive" || path === "drafts") &&
            status === "ARCHIVED" &&
            decoded.roles.find((obj) => obj === "ADMIN") && (
              <Button
                className={styles.cardButton}
                onClick={() => onClickPublish()}
                variant="success"
              >
                Опубликовать
              </Button>
            )}
          {path === "drafts" &&
            status === "PUBLISHED" &&
            decoded.roles.find((obj) => obj === "ADMIN") && (
              <Button
                className={styles.cardButton}
                onClick={() => archiveDraft()}
                variant="secondary"
              >
                <ArchiveDraftIco /> В архив
              </Button>
            )}
          <div className={styles.rightButtons}>
            {path === "drafts" &&
              status === "DRAFT" && (
                <DeleteDraftIco
                  className={styles.deleteButton}
                  onClick={() => onClickDelete()}
                />
              )}
            {status === "PUBLISHED" ||
              (status === "REVIEW" && (
                <Button
                  className={styles.cardButton}
                  onClick={() => onClickBackToDraft()}
                  variant="info"
                >
                  <ReviewDraftIco />В черновик
                </Button>
              ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default ArticleCard;
