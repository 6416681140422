import React from 'react';

import styles from "./ModalAddRole.module.scss";

import Button from "../Button";

type ModalWindowProps = {
  content: any,
  header?: string, 
  button?: () => void, 
  id: string
}

const ModalWindow: React.FC<ModalWindowProps> = ({content, header, button, id}) => {
  return (
    <div
    className="modal fade"
    id={id}
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabIndex={-1}
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
  >
    <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
      <div className={`modal-content${" " + styles.modalContent}`}>
        <div className={`modal-header${" " + styles.modalHeader}`}>
            {header && <h2>{header}</h2>}
          <div
            data-type="button"
            className={styles.buttonClose}
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            X
          </div>
        </div>
        <div className={styles.modalBody}>
          {content}
        </div>
        <div className={`modal-footer${" " + styles.modalFooter}`}>
          {button && <Button
            type={"button"}
            toggle="modal"
            target="#banModal"
            text={"Подтвердить"}
            onClickFunction={button}
          />}
        </div>
      </div>
    </div>
  </div>
  )
}

export default ModalWindow