import axios from "axios";
import React, { ChangeEvent } from "react";
import toast from "react-hot-toast";

import Button from "react-bootstrap/Button";

import styles from "./AddNewTag.module.scss";

import { ReactComponent as BannerFileIco } from "../../assets/ico/bannerFile.svg";
import { ReactComponent as ChangeIco } from "../../assets/ico/change.svg";

import { useAppDispatch } from "../../store/store";
import { ExistingTagProps, setNewTag } from "../../store/tags/tagsSlice";

type AddNewTagProps = {
  token: string;
  deleteTagMedia: (id: string, url: string) => void;
};

const AddNewTag: React.FC<AddNewTagProps> = ({ token, deleteTagMedia }) => {
  const dispatch = useAppDispatch();

  const [tagId, setTagId] = React.useState("");
  const [newTagValue, setNewTagValue] = React.useState("");
  const [newTagDescription, setNewTagDescription] = React.useState("");
  const [newTagBannerUrl, setNewTagBannerUrl] = React.useState("");

  React.useEffect(() => {
    clearInputs();
  }, []);

  const clearInputs = () => {
    setNewTagValue("");
    setNewTagDescription("");
    setNewTagBannerUrl("");
    setTagId("");
  };

  const addNewTag = async (event: ChangeEvent<HTMLInputElement>) => {
    if (!newTagValue || !newTagDescription) {
      toast.error("Добавьте название и описание тега!");
      return;
    }

    const id = await createNewTag();

    if (!id) {
      return;
    }

    addTagMedia(id, event);
  };

  const createNewTag = async () => {
    try {
      const { data } = await axios.post<ExistingTagProps>(
        "/api/v1/article/tag",
        {
          name: newTagValue,
          description: newTagDescription,
        },
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      );

      setTagId(data.id);
      return data.id;
    } catch (err) {
      console.log(err);
      toast.error("Такой тег уже существует!");
      clearInputs();
    }
  };

  const addTagMedia = async (
    id: string,
    event: ChangeEvent<HTMLInputElement>
  ) => {
    try {
      const files = event.target.files;

      if (files === null) {
        return;
      }

      const formData = new FormData();
      formData.append("file", files[0]);

      const { data } = await axios.post<ExistingTagProps>(
        `/api/v1/article/tag/${id}/media`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: "Bearer " + token,
          },
        }
      );
      setNewTagBannerUrl(data.banner_url ? data.banner_url : "");
    } catch (err) {
      console.log(err);
      toast.error("Ошибка при загрузке фото! Попробуйте позже");
      clearInputs();
    }
  };

  const onClickEdit = async () => {
    try {
      if (!newTagValue || !newTagDescription || !newTagBannerUrl) {
        toast.error("Добавьте название, описание тега и обложку!");
        return;
      }

      const { data } = await axios.put<ExistingTagProps>(
        `/api/v1/article/tag/${tagId}`,
        {
          name: newTagValue,
          description: newTagDescription,
        },
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      );
      dispatch(setNewTag(data));
      clearInputs();
      toast.success("Сохранение прошло успешно");
    } catch (err) {
      console.log(err);
      toast.error("Такой тег уже существует!");
    }
  };

  const changeTagPhoto = async (event: ChangeEvent<HTMLInputElement>) => {
    deleteTagMedia(tagId, newTagBannerUrl);
    addTagMedia(tagId, event);
  };

  return (
    <div className={styles.backgroundNewTag}>
      <label>Название тега:</label>
      <input
        value={newTagValue}
        onChange={(event) => setNewTagValue(event.target.value)}
      />
      <label>Описание:</label>
      <input
        value={newTagDescription}
        onChange={(event) => setNewTagDescription(event.target.value)}
      />
      {newTagBannerUrl ? (
        <>
          <label
            htmlFor={"changePhoto"}
            className={styles.changeBannerPhotoButton}
          >
            Change
          <ChangeIco/>
          </label>
          <img src={newTagBannerUrl} alt="Фото" />
          <input
            onChange={(event) => changeTagPhoto(event)}
            className={styles.changeFileInput}
            id={"changePhoto"}
            type="file"
          />
        </>
      ) : (
        <div className={styles.pngBlock}>
          <label className={styles.bannerFile} htmlFor={"tagPhoto"}>
            <BannerFileIco />
            Select a file
          </label>
          <input
            onChange={(event) => addNewTag(event)}
            className={styles.bannerFileInput}
            id={"tagPhoto"}
            type="file"
          />
        </div>
      )}

      <Button
        aria-disabled="true"
        data-type="button"
        data-bs-dismiss={
          !newTagValue || !newTagDescription || !newTagBannerUrl ? "" : "modal"
        }
        className={styles.addNewTagButton}
        onClick={() => onClickEdit()}
        variant="success"
        disabled={!newTagValue || !newTagDescription || !newTagBannerUrl ? true : false}
      >
        Сохранить
      </Button>
    </div>
  );
};

export default AddNewTag;
