import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { RootState } from "../store";

export const fetchExistingTags = createAsyncThunk<
  TagsDataProps,
  Record<string, string>
>("existingTags/fetchExistingTags", async (params) => {
  const { str } = params;
  const { data } = await axios.get<TagsDataProps>(
    `/api/v1/article/tag/${str}?limit=50&skip=0`
  );
  return data;
});

type TagsDataProps = {
  items: ExistingTagProps[];
  items_size: number;
  amount_of_pages: number;
};

export type ExistingTagProps = {
  id: string;
  name: string;
  description: string;
  banner_url: string | null;
};

interface ExistingTagSliceState {
  existingTags: ExistingTagProps[] | [];
  status: "loading" | "success" | "error" | null;
  items_size: number;
  amount_of_pages: number;
}

const initialState: ExistingTagSliceState = {
  existingTags: [],
  status: null,
  items_size: 0,
  amount_of_pages: 1,
};

export const existingTagsSlice = createSlice({
  name: "existingTags",
  initialState,
  reducers: {
    setNewTag: (state, action) => {
      if (!state.existingTags) {
        let tags = [];
        tags.push(action.payload);
        state.existingTags = tags;
        return;
      }
      let tags = state.existingTags;
      state.existingTags = [...tags, action.payload];
    },
    deleteTag: (state, action) => {
      const findTag = state.existingTags.filter(
        (obj) => obj.id !== action.payload
      );

      if (!findTag) {
        return;
      }
      state.existingTags = findTag;
    },
    filterExistingTags: (state, action) => {
      state.existingTags = state.existingTags.filter((obj) => obj.name !== action.payload);
    },
    clearExistingTags: (state) => {
      state.existingTags = [];
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchExistingTags.pending, (state) => {
      state.status = "loading";
      state.existingTags = [];
    });
    builder.addCase(fetchExistingTags.fulfilled, (state, action) => {
      state.status = "success";
      state.existingTags = action.payload.items;
    });
    builder.addCase(fetchExistingTags.rejected, (state, action) => {
      state.status = "error";
      state.existingTags = [];
    });
  },
});

export const selectExistingTags = (state: RootState) => state.existingTagsSlice;

export const { setNewTag, deleteTag, filterExistingTags, clearExistingTags } = existingTagsSlice.actions;

export default existingTagsSlice.reducer;
