import React, { ChangeEvent } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import toast from "react-hot-toast";
import axios from "axios";
import {
  DragDropContext,
  DroppableProvided,
  DropResult,
  Droppable,
} from "react-beautiful-dnd";

import Button from "react-bootstrap/Button";

import styles from "./DraftEditor.module.scss";

import { ReactComponent as ExitButtonIco } from "../../assets/ico/exitButton.svg";

import LoadingBar from "../../components/LoadingBar";
import WarningModal from "../../components/WarningModal";

import { Input } from "@mui/material";

import { useAppDispatch } from "../../store/store";
import {
  BlockProps,
  PictureBlockProps,
  QuoteBlockProps,
} from "../../store/ownDrafts/ownDraftsSlice";
import { selectEditorValues } from "../../store/editor/editorSlice";
import { selectAdminUser } from "../../store/adminUser/adminUserSlice";
import { toggleModal } from "../../store/sessionModal/sessionModalSlice";
import {
  fetchOneDraft,
  selectOneDraft,
} from "../../store/oneDraft/oneDraftSlice";
import Block from "../../components/Block";

const DraftEditor: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { id } = useParams();

  const { title, banner_url, blocks, tags, lang } =
    useSelector(selectEditorValues);

  const { oneDraft, status } = useSelector(selectOneDraft);
  const { user: adminUser } = useSelector(selectAdminUser);
  const token = adminUser !== null ? adminUser.access_token : "";

  const [isLoading, setIsLoading] = React.useState(false);

  const [draftTitle, setDraftTitle] = React.useState(title);
  const [draftBannerUrl, setDraftBannerUrl] = React.useState(banner_url);
  const [blocksValues, setBlocksValues] = React.useState(blocks);
  const [draftTags, setDraftTags] = React.useState<string[]>(tags);

  const [langValue, setLangValue] = React.useState(lang);

  React.useEffect(() => {
    dispatch(fetchOneDraft({ token, id: id !== undefined ? id : "" }));
  }, [dispatch, token, id]);

  React.useEffect(() => {
    setIsLoading(true);
    if (status === "success" && oneDraft) {
      const currentDraft = oneDraft;

      setDraftTitle(currentDraft.title);
      setDraftBannerUrl(currentDraft.banner_url);
      if (currentDraft.blocks.length !== 0) {
        setBlocksValues(currentDraft.blocks);
      } else {
        setBlocksValues([{ type: "TEXT", data: "" }]);
      }
      setDraftTags(currentDraft.tags);
      setLangValue(currentDraft.lang);
      setIsLoading(false);
    }
  }, [status, id, oneDraft]);

  React.useEffect(() => {
    if (status === "error") {
      dispatch(toggleModal(true));
    }
  }, [status, dispatch]);

  const saveDraft = async (newBlocks?: BlockProps[], bannerUrl?: string) => {
    try {
      await axios.put(
        `/api/v1/admin/article/${id}`,
        {
          title: draftTitle,
          banner_url: bannerUrl ? bannerUrl : draftBannerUrl,
          tags: draftTags,
          blocks: newBlocks ? newBlocks : blocksValues,
          lang: langValue,
        },
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      );
    } catch (err) {
      console.log(err);
      toast.error(
        "Произошла ошибка при сохранении, проверьте все ли блоки заполненны"
      );
    }
  };

  const onClickSave = () => {
    saveDraft();
    toast.success("Сохранение прошло успешно!");
  };

  const onDragEnd = (result: DropResult) => {
    if (!result.destination) {
      return;
    }
    const newItems = [...blocksValues];
    const [removed] = newItems.splice(result.source.index, 1);
    newItems.splice(result.destination.index, 0, removed);

    setBlocksValues(newItems);
  };

  if (status === "error") {
    return <WarningModal />;
  }

  if (isLoading) {
    return <LoadingBar loading={isLoading} />;
  }

  return (
    <div className={styles.background}>
      <div className={styles.draftHead}>
        <Input
          onChange={(e) => setDraftTitle(e.target.value)}
          value={draftTitle}
          className={styles.titleField}
          placeholder={"Заголовок..."}
        />

        <div className={styles.exitButton} onClick={() => navigate(-1)}>
          <ExitButtonIco />
        </div>
      </div>

      <DragDropContext onDragEnd={onDragEnd}>
        <div className={styles.backgroundEditor}>
          <Droppable droppableId="droppable">
            {(provided: DroppableProvided) => (
              <div {...provided.droppableProps} ref={provided.innerRef}>
                {blocksValues.map((obj, index) => (
                  <Block
                    id={id ? id : ""}
                    langValue={langValue}
                    token={token}
                    key={index}
                    index={index}
                    obj={obj}
                    draftTitle={draftTitle}
                    blocksValues={blocksValues}
                    setBlocksValues={setBlocksValues}
                    saveDraft={saveDraft}
                  />
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </div>
      </DragDropContext>

      <Button
        className={styles.editButton}
        onClick={() => onClickSave()}
        variant="secondary"
      >
        Сохранить
      </Button>
    </div>
  );
};

export default DraftEditor;
